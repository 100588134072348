<template>
  <div class="home">
    <div class="container-1">
      <img class="avatar" src="./avatar.gif" alt="">
      <p class="hello">Привет! Я Дмитрий</p>
      <form action="/projects"><button class="projects" >Проекты</button></form>
      <form action="/social"><button class="social" >Соц. Сети</button></form>
    </div>
    <div class="container-2">
      <div class="text">
        Сейчас я в основном занимаюсь Front-End разработкой, но в конце весны 2024 года начал изучение Back-End разработки, чтобы в будущем стать FullStack разработчиком. На данный момент я знаю 3 языка программирования, а именно Python, C#, Vue.js (На котором и написан данный сайт). Из интересных проектов хочу отметить собственный мессенджер (TheRevision) который был написан на Python (Серверная часть) и C# (Клиентская часть), также хочу упомянуть свой сайт написанный на vue.js - IP-INFO (Не придумал ещё ему название, так как сайт был сделан за 3 часа ещё весной 2024 года) который позволял получать информацию об IP адресе без регистрации. Также я владею 2 майнкрафт хостингами, первый был сделан вместе с pixellav и является платным, а второй бесплатный сделанный лично мною. 
      </div>
    </div>
    <footer>Copyright © 2024 Dmitry. Все права защищены.</footer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
*{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 3.5%;
  margin-left: 3.5%;
  width: 10%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 20%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:20%;
  border-radius: 1vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 55%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:20%;
  border-radius: 1vw;
}
.hello {
  font-size: 200%;
  margin-left: 15.5%;
  margin-top: 5%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 8%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 9%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;

}
.social {
  width: 8%;
  font-family: "Ubuntu", sans-serif;
  height: 5%;
  position: fixed;
  margin-left: 19%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;
}
.text{
  margin: 2%;
  font-size: 150%;
}
footer {
  bottom: 3.5%;
  position: fixed;
  left: 37%;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
@media screen and (max-width: 1580px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 2.5%;
  margin-left: 2.5%;
  width: 15%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 20%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:20%;
  border-radius: 1vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 55%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:20%;
  border-radius: 1vw;
}
.hello {
  font-size: 200%;
  margin-left: 22.5%;
  margin-top: 6%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 12%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 13.5%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;

}
.social {
  width: 12%;
  font-family: "Ubuntu", sans-serif;
  height: 5%;
  position: fixed;
  margin-left: 28%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;
}
.text{
  margin: 2%;
  font-size: 130%;
}
footer {
  bottom: 3.5%;
  position: fixed;
  width: 100%;
  text-align: center;
  left:0%;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
@media screen and (max-height: 880px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 0.5%;
  margin-left: 0.5%;
  width: 10%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 20%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:20%;
  border-radius: 1vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 55%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:20%;
  border-radius: 1vw;
}
.hello {
  font-size: 200%;
  margin-left: 12.5%;
  margin-top: 2%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 9%;
  height: 7%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 7.5%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;

}
.social {
  width: 9%;
  font-family: "Ubuntu", sans-serif;
  height: 7%;
  position: fixed;
  margin-left: 18%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;
}
.text{
  margin: 2%;
  font-size: 130%;
}
footer {
  bottom: 3.5%;
  position: fixed;
  width: 100%;
  text-align: center;
  left:0%;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
/*@media screen and (max-width: 980px) {
  *{
  color:white
}
.home{
  background-color: rgb(5, 5, 5);
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 4.5%;
  margin-left: 4.5%;
  width: 15%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 20%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:20%;
  border-radius: 1vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 60%;
  height: 55%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:20%;
  border-radius: 1vw;
}
.hello {
  font-size: 200%;
  margin-left: 22.5%;
  margin-top: 5%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 8%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 13.5%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;

}
.social {
  width: 8%;
  font-family: "Ubuntu", sans-serif;
  height: 5%;
  position: fixed;
  margin-left: 25%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;
}
.text{
  margin: 2%;
  font-size: 150%;
}
footer {
  bottom: 3.5%;
  position: fixed;
  left: 0%;
  text-align: center;
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}*/
@media screen and (max-width: 950px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 5.5%;
  margin-left: 5.5%;
  width: 20%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 80%;
  height: 20%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:10%;
  border-radius: 1vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 80%;
  height: 55%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:10%;
  border-radius: 1vw;
}
.hello {
  font-size: 200%;
  margin-left: 30.5%;
  margin-top: 10%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 15%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 23.5%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;

}
.social {
  width: 15%;
  height: 5%;
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  margin-left: 40%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 22%;
  font-size: 120%;
}
.text{
  margin: 4%;
  font-size: 130%;
}
footer {
  bottom: 3.5%;
  position: fixed;
  text-align: center;
  width: 100%;
  left:0px;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
@media screen and (max-width: 650px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 4.5%;
  margin-left: 4.5%;
  width: 15%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 12%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:5%;
  border-radius: 3vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 65%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:5%;
  border-radius: 3vw;
}
.hello {
  font-size: 150%;
  margin-left: 28.5%;
  margin-top: 5%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 25%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 25%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 120%;

}
.social {
  width: 25%;
  height: 5%;
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  margin-left: 55%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 120%;
}
.text{
  margin: 2%;
  font-size: 115%;
}
footer {
  bottom: 2%;
  position: fixed;
  left: 0%;
  width: 100%;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
@media screen and (max-width: 450px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 4.5%;
  margin-left: 4.5%;
  width: 20%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 12%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:5%;
  border-radius: 3vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 65%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:5%;
  border-radius: 3vw;
}
.hello {
  font-size: 150%;
  margin-left: 28.5%;
  margin-top: 6%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 25%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 25%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 120%;

}
.social {
  width: 25%;
  height: 5%;
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  margin-left: 55%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 120%;
}
.text{
  margin: 2%;
  font-size: 115%;
}
footer {
  bottom: 2%;
  position: fixed;
  left: 0%;
  width: 100%;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
@media screen and (max-width: 390px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 4.5%;
  margin-left: 4.5%;
  width: 20%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 12%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:5%;
  border-radius: 3vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 65%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:5%;
  border-radius: 3vw;
}
.hello {
  font-size: 150%;
  margin-left: 28.5%;
  margin-top: 6%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 25%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 25%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 90%;

}
.social {
  width: 25%;
  height: 5%;
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  margin-left: 55%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 90%;
}
.text{
  margin: 2%;
  font-size: 100%;
}
footer {
  bottom: 2%;
  position: fixed;
  left: 0%;
  width: 100%;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
@media screen and (max-width: 380px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 4.5%;
  margin-left: 4.5%;
  width: 20%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 12%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:5%;
  border-radius: 3vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 65%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:5%;
  border-radius: 3vw;
}
.hello {
  font-size: 150%;
  margin-left: 28.5%;
  margin-top: 6%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 25%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 25%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 110%;

}
.social {
  width: 25%;
  height: 5%;
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  margin-left: 55%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 100%;
}
.text{
  margin: 2%;
  font-size: 100%;
}
footer {
  bottom: 2%;
  position: fixed;
  left: 0%;
  width: 100%;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
@media screen and (max-width: 379px) {
  *{
  color:white
}
.home{
 /* background-color: rgb(5, 5, 5);*/
  background-image: url("bg.gif");
  background-repeat: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.avatar {
  border-radius: 100%;
  position: absolute;
  margin-top: 2%;
  margin-left: 2%;
  width: 20%;
}
.container-1{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 12%;
  font-family: "Ubuntu", sans-serif;
  top: 10%;
  position: fixed;
  left:5%;
  border-radius: 3vw;
}
.container-2{
  background-color: rgb(8, 8, 8);
  border: solid 4px rgb(14, 14, 14);
  width: 90%;
  height: 65%;
  bottom: 10%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  left:5%;
  border-radius: 3vw;
}
.hello {
  font-size: 150%;
  margin-left: 28.5%;
  margin-top: 3%;
  color: rgb(236, 236, 236);
}
.projects {
  width: 25%;
  height: 5%;
  position: fixed;
  font-family: "Ubuntu", sans-serif;
  margin-left: 25%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 110%;

}
.social {
  width: 25%;
  height: 5%;
  font-family: "Ubuntu", sans-serif;
  position: fixed;
  margin-left: 55%;
  border-radius: 15px;
  border: solid 4px rgb(12, 12, 12);
  background-color: rgb(7, 7, 7);
  top: 16.5%;
  font-size: 100%;
}
.text{
  margin: 2%;
  font-size: 100%;
}
footer {
  bottom: 2%;
  position: fixed;
  left: 0%;
  width: 100%;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  font-size: 150%;
}
}
</style>